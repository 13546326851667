//import { useState } from 'react';
import Navbar from '../navigationbar';
import Footer from '../footer';
import "./../css/profiles.css";

const urlString = ""
const urlString2 = ""

export default function Ally() {

    window.scrollTo(0, 0)

    return (<>
        <Navbar/>
            <a style={{"marginLeft": "30px"}} href={urlString2 + "/#/directory"}>❮ BACK TO DIRECTORY</a>
            <h1 style={{"textAlign": "center", "fontSize": "40px"}}>Voo, Ally</h1>
            <div className="profileBox">
                <div className="profileInnerBox">
                    <img src={urlString + "people/ally.jpg"} className="profileImage" alt=""></img>
                    <div className="widthSmaller">
                        <div className="profileInfoBox" id="specialProfile">
                            <h2 className="profileMargin">Title:</h2>
                            <h2 className="profileMargin" id="profileLighterFont">Design Specialist</h2>
                            <h2 className="profileMargin">Division:</h2>
                            <h2 className="profileMargin" id="profileLighterFont">Financial Planning and Analysis</h2>
                            <h2 className="profileMargin">Specialities:</h2>
                            <div>
                                <h2 className="profileMargin" id="profileLighterFont">Excel</h2>
                                <h2 className="profileMargin" id="profileLighterFont">Powerpoint</h2>
                                <h2 className="profileMargin" id="profileLighterFont">Architectural Design</h2>
                            </div>
                        </div>
                        <div className="profileInfoBox" id="secondSpecial">
                            <h2 className="profileMargin">Email:</h2>
                            <h2 className="profileMargin" id="profileLighterFont">allyvoo9@gmail.com</h2>
                            <h2 className="profileMargin">Cell Phone:</h2>
                            <h2 className="profileMargin" id="profileLighterFont">(909)-368-6608</h2>
                        </div>
                    </div>
                    
                </div>
            </div>
            <h1 style={{"textAlign": "center", "fontSize": "40px", "marginBottom": "5vh", "marginTop": "5vh"}}>Short Biography</h1>
            <div className="bio">
                <p1 style={{"fontSize": "24px", "lineHeight": "150%"}}>&emsp;Ally Voo embarked on her professional journey with Tang Structural Engineers in July of 2024, bringing with her a fresh perspective and a wealth of knowledge from her recent bachelor's degree in business/economics obtained from UCI earlier that same year.
<br></br>&emsp;                    
Before joining the firm, Ally garnered invaluable experience at the Orange County Transportation Agency (OCTA) in their financial planning and analysis department. During her tenure there, she assumed pivotal roles, including overseeing budget analysis, forecasting financial trends, and implementing strategic financial initiatives. Her proactive approach and proficiency in financial management were key assets that distinguished her contributions at OCTA. Her transition into the design sector of Tang Structural Engineers marked the beginning of her impactful contributions to both project development and client relations.
<br></br>&emsp;
Driven by her passion for innovative design, Ally plays a pivotal role in shaping the company's creative direction. Her involvement spans across marketing strategies aimed at showcasing Tang Structural Engineers' cutting-edge solutions. Ally thrives on developing new and forward-thinking designs tailored to meet the unique needs of each client, ensuring that every project not only meets but exceeds expectations.
<br></br>&emsp;
Beyond her creative prowess, Ally takes pride in fostering strong relationships with key stakeholders. She frequently engages with city officials, leveraging her excellent communication skills to secure vital project support and approvals. Her proactive approach ensures that Tang Structural Engineers maintains a seamless workflow, from concept to completion.
<br></br>&emsp;
Ally's journey is characterized by her dedication to excellence and her commitment to pushing the boundaries of architectural innovation. As she continues to grow within the company, her passion for creating spaces that inspire and endure remains at the forefront of her professional endeavors.</p1>
            </div>
        <Footer/>
    </>)
}

//https://www.jgma.com/